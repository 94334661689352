import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import ReactResizeDetector from 'react-resize-detector';

const TicketDetailBorder = styled.hr`
  margin-top: 4px;
  margin-bottom: 0;
  color: 1px solid #DBDEE2;
`;

const TicketDetailsContainer = styled.div`
  height: ${({ viewToggle }) => (viewToggle ? 'initial' : '38px')};
  overflow: hidden;
  padding: 8px 8px 0;
  color: #A2AAB3;
  font-size: 14px;
  letter-spacing: 0.3px;
  transition: height 0.4s;
`;

const ToggleViewButton = styled.div`
  padding: 0 8px;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 22px;
  cursor: pointer;
  color: ${({ themeColor }) => (themeColor)};
  display: ${({ showToggle }) => (showToggle ? 'block' : 'none')};
`;

const TicketDetails = ({ details, locale, themeColor }) => {
  const { t } = useTranslation();

  const [viewToggle, setviewToggle] = useState(true);
  const [showToggle, setshowToggle] = useState(false);

  const CustomComponent = ({ height }) => {
    if (height > 38 && !showToggle) {
      setshowToggle(true);
      setviewToggle(false);
    }
    return ReactHtmlParser(details);
  };
  return (
    <>
      <TicketDetailBorder />
      <TicketDetailsContainer viewToggle={viewToggle}>
        <ReactResizeDetector handleWidth handleHeight>
          <CustomComponent />
        </ReactResizeDetector>
      </TicketDetailsContainer>
      <ToggleViewButton
        showToggle={showToggle}
        themeColor={themeColor}
        onClick={() => { setviewToggle(!viewToggle); }}
      >
        {viewToggle ? t('regsvp.show_less', { locale: locale.emsMapping }) : t('regsvp.show_more', { locale: locale.emsMapping })}
      </ToggleViewButton>
    </>

  );
};

TicketDetails.propTypes = {
  details: PropTypes.string,
  themeColor: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
};

TicketDetails.defaultProps = {
  details: '',
};

export default TicketDetails;
