import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useBuiltInPaymentEnabled from 'hooks/useBuiltInPaymentEnabled';

const useThankYouPageIndex = (): number => {
  const builtInPaymentEnabled = useBuiltInPaymentEnabled();
  const includeTicketing = useSelector((s) => s.includeTicketing, []);
  const defaultTicket = useSelector((s) => s.defaultTicket, []);

  return useMemo(
    () => {
      let thankYouPageIndex = 2;
      if (includeTicketing) {
        thankYouPageIndex = builtInPaymentEnabled ? 4 : 3;
      } else {
        const isPaidDefaultTicket = parseFloat(defaultTicket.price.amount) > 0;
        thankYouPageIndex = isPaidDefaultTicket ? 3 : 2;
      }
      return thankYouPageIndex;
    },
    [builtInPaymentEnabled],
  );
};

export default useThankYouPageIndex;
