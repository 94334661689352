import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';
import { generate } from '@ant-design/colors';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import trashIcon from 'registration_form_builder/Image/trash.svg';
import triangle from 'registration_form_builder/Image/triangle.svg';
import TicketDetails from './TicketDetails';

import 'dayjs/locale/en';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-hk';
import 'dayjs/locale/ko';
import 'dayjs/locale/ja';

dayjs.extend(relativeTime);

const TicketInfoContainer = styled.div`
  padding: 0 8px;
  color: #484848;
  font-size: 18px;
  letter-spacing: 0.34px;
  line-height: 24px;
`;

const TicketContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
`;

const DeleteIconContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
`;

const DeleteIconWrapper = styled.div`
  position: relative;
`;

const DeleteMessage = styled.div`
  width: 182px;
  height: 58px;
  position: absolute;
  top: -68px;
  right: -16px;
  padding: 8px;
  background-color: #484848;
  color: white;
  border-radius: 4px;
  letter-spacing: 0.3px;
`;

const DeleteIcon = styled.img`
  cursor: ${({ totalTicketCount }) => (totalTicketCount > 1 ? 'pointer' : 'not-allowed')};
  opacity: ${({ totalTicketCount }) => (totalTicketCount > 1 ? 1 : 0.5)};
`;

const DeleteTriangle = styled.img`
  position: absolute;
  top: -10px;
  right: 0;
`;

const TicketSelect = styled.div`
  width: 120px;
  float: right;
`;

const TicketName = styled.div`
  font-weight: bold;
  padding-bottom: 16px;
`;

const TicketPricingDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const SalesEnd = styled.div`
  float: right;
  border-radius: 12px;
  padding: 5px 16px;
  background-color: #F0F1F2;
  color: #484848;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 14px;
`;

const renderTicketDetails = (details, locale, themeColor) => {
  if (details) {
    return (
      <TicketDetails details={details} locale={locale} themeColor={themeColor} />
    );
  }
  return null;
};

const SelectedTicket = ({
  maxTicketCount,
  isDisabled,
  ticketInfo,
  deleteTicket,
  index,
  onChange,
  themeColor,
  locale,
  totalTicketCount,
  minTicketCount,
  initialTicketCount,
  selectedQuatity,
  firstVisit,
  checkOrderExistBeforeAction,
  footerColorMode,
}) => {
  const { t } = useTranslation();

  const {
    ticket: {
      name,
      price: { amount, currencyCode },
      details,
      salesStartAt,
      salesEndAt,
      remainingQuota,
      showRemainingTickets,
      allowZeroQuantity,
    },
  } = ticketInfo;
  const generateOptions = useMemo(() => {
    const minQuantity = Math.min(
      minTicketCount,
      remainingQuota === null ? minTicketCount : remainingQuota,
    );
    const maxQuantity = Math.min(
      maxTicketCount,
      remainingQuota === null ? maxTicketCount : remainingQuota,
    );

    let options = [];
    if (maxQuantity >= minQuantity) {
      options = Array(maxQuantity - minQuantity + 1)
        .fill()
        .map((_, i) => ({ value: i + minQuantity, label: i + minQuantity }));
    }
    if (initialTicketCount && !options.length || (allowZeroQuantity && minTicketCount !== 0)) {
      options.unshift({ value: 0, label: 0 });
    }
    return options;
  }, [minTicketCount, remainingQuota, maxTicketCount, initialTicketCount, allowZeroQuantity]);

  const themeColorList = useMemo(() => (generate(themeColor)), [themeColor]);
  const isBefore = useMemo(() => (dayjs(salesEndAt).isBefore(dayjs())), [salesEndAt]);
  const isAfter = useMemo(() => (dayjs(salesStartAt).isAfter(dayjs())), [salesStartAt]);
  const availableQuota = useMemo(() => (
    remainingQuota === null || remainingQuota >= minTicketCount
      ? remainingQuota : 0), [remainingQuota, minTicketCount]);

  const customStyles = useMemo(() => ({
    menu: (provided) => ({
      ...provided,
      zIndex: 3,
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      color: isFocused || isSelected ? footerColorMode : provided.color,
    }),
  }), [footerColorMode]);

  useEffect(() => {
    if (
      !isBefore && !isAfter && availableQuota !== 0 && firstVisit && generateOptions[0].value !== 0
    ) {
      onChange({ index, selectedQuatity: generateOptions[0].value });
    }
  }, []);

  const numberFormatter = useMemo(() => {
    let language = 'en';
    if (currencyCode === 'EUR') {
      language = 'de';
    }
    return new Intl.NumberFormat(language,
      {
        style: 'currency',
        currency: currencyCode,
      });
  }, [currencyCode]);

  const intlTicketPrice = useMemo(() => (
    numberFormatter.format(parseFloat(amount).toFixed(2))
  ), [amount, numberFormatter]);

  const selectedQuantityOptionValue = useMemo(() => (
    generateOptions.filter((option) => option.value === selectedQuatity)
  ), [generateOptions, selectedQuatity]);

  const ticketSelector = useMemo(() => {
    if (!isBefore && !isAfter && remainingQuota !== 0) {
      return (
        <TicketSelect>
          <Select
            isDisabled={!isDisabled}
            options={generateOptions}
            onChange={(newSelected) => {
              checkOrderExistBeforeAction(onChange, { index, selectedQuatity: newSelected.value });
            }}
            value={selectedQuantityOptionValue}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: themeColor,
                primary25: themeColorList[2],
                primary50: themeColorList[1],
                primary75: themeColorList[0],
              },
            })}
            styles={customStyles}
          />
        </TicketSelect>
      );
    } if (remainingQuota === 0) {
      return (
        <SalesEnd>
          {t('regsvp.period.sold_out', {
            locale: locale.emsMapping,
          })}
        </SalesEnd>
      );
    } if (isBefore) {
      return (
        <SalesEnd>
          {t('regsvp.period.sales_ended', {
            locale: locale.emsMapping,
          })}
        </SalesEnd>
      );
    } if (isAfter) {
      dayjs.locale(locale.emsMapping.toLowerCase());
      return (
        <SalesEnd>
          {t('regsvp.period.will_available', {
            period_before_start: t('regsvp.period.in', {
              duration: dayjs(salesStartAt).fromNow(true),
              locale: locale.emsMapping,
            }),
            locale: locale.emsMapping,
          })}
        </SalesEnd>
      );
    }
    return null;
  }, [
    isBefore,
    isAfter,
    isDisabled,
    generateOptions,
    themeColor,
    themeColorList,
    salesStartAt,
    onChange,
    index,
    locale,
    remainingQuota,
    checkOrderExistBeforeAction,
    customStyles,
    t,
    selectedQuatity,
  ]);

  return (
    <TicketContainer>
      {!isDisabled && (
      <DeleteIconContainer>
        <DeleteIconWrapper>
          <DeleteIcon
            totalTicketCount={totalTicketCount}
            src={trashIcon}
            onClick={() => {
              if (totalTicketCount > 1) {
                deleteTicket({ ticketInfo, index });
              }
            }}
          />
          {totalTicketCount === 1 && (
            <>
              <DeleteMessage>
                Ticketing Page must have at least one ticket type.
              </DeleteMessage>
              <DeleteTriangle src={triangle} />
            </>
          )}
        </DeleteIconWrapper>
      </DeleteIconContainer>
      )}
      <TicketInfoContainer>
        {ticketSelector}
        <TicketName>
          {name}
        </TicketName>
        <TicketPricingDetails>
          <div>
            {amount === '0.00' ? t('ticketing.free_ticket', { locale: locale.emsMapping }) : `${intlTicketPrice}`}
          </div>
          {remainingQuota && remainingQuota > 0 && showRemainingTickets && (
            <div>
              {t('ticketing.remaining_tickets', {
                remaining_quota: remainingQuota,
              })}
            </div>
          )}
        </TicketPricingDetails>
      </TicketInfoContainer>
      {renderTicketDetails(details, locale, themeColor)}
    </TicketContainer>
  );
};

SelectedTicket.propTypes = {
  maxTicketCount: PropTypes.number,
  isDisabled: PropTypes.bool.isRequired,
  ticketInfo: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  deleteTicket: PropTypes.func,
  removeTicket: PropTypes.func,
  onChange: PropTypes.func,
  themeColor: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  totalTicketCount: PropTypes.number,
  minTicketCount: PropTypes.number,
  initialTicketCount: PropTypes.bool,
  selectedQuatity: PropTypes.number,
  firstVisit: PropTypes.bool,
  checkOrderExistBeforeAction: PropTypes.func,
  footerColorMode: PropTypes.string.isRequired,
};

SelectedTicket.defaultProps = {
  deleteTicket: () => {},
  removeTicket: () => {},
  onChange: () => {},
  checkOrderExistBeforeAction: () => {},
  totalTicketCount: 0,
  minTicketCount: 0,
  initialTicketCount: true,
  selectedQuatity: 0,
  firstVisit: true,
  maxTicketCount: 1,
};

export default SelectedTicket;
