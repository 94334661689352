import { FC, memo as memoComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { connect } from 'react-redux';
import useBuiltInPaymentEnabled from 'hooks/useBuiltInPaymentEnabled';

interface FormStageComponent {
  disabled?: boolean;
  selectable?: boolean;
  themeColor?: string;
  selected?: boolean;
}

const FormStageContainer = styled.div<FormStageComponent>`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  text-align: center;
  box-sizing: content-box;
  position: relative;
  padding: 0 12px;
  transition: all 0.3s;

  @media screen and (max-width: 832px) {
    display: ${({ selected }) => (selected ? 'flex' : 'none')};
    font-size: 1.1rem;
  }
`;

const NumberContainer = styled.div<FormStageComponent>`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  position: relative;
  color: white;
  background-color:
    ${({ selected, themeColor, disabled }) => {
    if (disabled) {
      return '#DBDEE2';
    }
    if (selected) {
      return themeColor;
    }
    return '#DBDEE2';
  }};

  @media screen and (max-width: 832px) {
    display: none;
  }
`;

const FormStageItem = styled.div<FormStageComponent>`
  display: inline-flex;
  border-radius: 4px;
  margin: 2px;
  align-items: center;
  transition: all 0.25s;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'initial')};

  &:hover {
    background-color: white;

    ${NumberContainer} {
      background-color: ${({ themeColor }) => (themeColor || '#DBDEE2')};
      color: white;  
    }
  }
`;

const FormStageDisplayContainer = styled.div`
  position: relative;
  display: flex;
`;

const Index = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const DesktopFormStageDisplay = styled.span`
  display: block;
  padding-left: 6px;

  @media screen and (max-width: 832px) {
    display: none;
  }
`;

const MobileFormStageDisplay = styled.span`
  display: none;
  padding: 0;
  padding-left: 6px;
  font-size: 0.95rem;
  font-weight: bold;

  @media screen and (max-width: 832px) {
    display: block;
  }
`;

interface FormStageButtonProps {
  index: number;
  selected: boolean;
  isSelectable: boolean;
  themeColor: string;
  updateFormStage: (index: number) => void;
  includeTicketing: boolean;
  eachFormStage: 'landing' | 'ticketing' | 'form' | 'review_order' | 'thank_you';
  locale: string;
  formStage: number,
  largestFormStage: number,
  numberSteps: number,
}

const FormStageButton: FC<FormStageButtonProps> = ({
  eachFormStage,
  index,
  selected,
  isSelectable,
  updateFormStage,
  themeColor,
  includeTicketing,
  locale,
  formStage,
  numberSteps,
}) => {
  const { t } = useTranslation();
  const stageMessage = t(`regform_v3_builder.stages.${eachFormStage}`);
  const mobileGreetingMessage = `${t('regform_v3.step_progress', {
    current: formStage + 1,
    total: numberSteps,
  })}: ${stageMessage}`;

  return (
    <FormStageContainer
      key={eachFormStage}
      id={eachFormStage}
      selected={selected}
      selectable={isSelectable}
    >
      <FormStageItem
        themeColor={themeColor}
        selectable={isSelectable}
        disabled={!includeTicketing && eachFormStage === 'ticketing'}
        onClick={() => (
          isSelectable && updateFormStage(index)
        )}
      >
        <NumberContainer
          themeColor={themeColor}
          selected={selected}
          selectable={isSelectable}
          disabled={!includeTicketing && eachFormStage === 'ticketing'}
        >
          <Index>{index + 1}</Index>
        </NumberContainer>
        <FormStageDisplayContainer>
          <DesktopFormStageDisplay>
            {stageMessage}
          </DesktopFormStageDisplay>
          <MobileFormStageDisplay>
            {mobileGreetingMessage}
          </MobileFormStageDisplay>
        </FormStageDisplayContainer>
      </FormStageItem>
    </FormStageContainer>
  );
};

const mapState = (state) => ({
  themeColor: state.themeColor,
  formStage: state.formStage,
  largestFormStage: state.largestFormStage,
  includeTicketing: state.includeTicketing,
});

const mapDispatch = ({
  formStage: { setFormStage, setPreviousFormStage, setNextFormStage },
}) => ({
  setFormStage,
  setPreviousFormStage,
  setNextFormStage,
});

export default connect(mapState, mapDispatch)(
  FormStageButton,
);
