import { useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Arrow from 'images/arrow.svg';
import useBuiltInPaymentEnabled from 'hooks/useBuiltInPaymentEnabled';
import useThankYouPageIndex from 'hooks/useThankYouPageIndex';
import XtraFlexbox from './XtraFlexbox';
import FormStageButton from './formStage/FormStageButton';

const FormStageSelectorWrapper = styled.div`
  width: 100%;
  padding: 12px 16px 0;
  margin: auto;

  @media screen and (max-width: 832px) {
    padding: 0;
    margin: 0;
    height: 50px;
  }
`;

const FormStageSelectorContainer = styled(XtraFlexbox)`
  border-radius: 8px;
  padding: 4px;
  background-color: #FFF;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 832px) {
    border-radius: 0;
  }
`;

const ArrowButtonContainer = styled.div`
  width: 36px;
  height: 11px;
  position: relative;
`;

const NavigationArrow = styled.img`
  display: block;
  cursor: pointer;
  padding: 16px;
  position: absolute;
  top: -16px;
`;

const BackArrowButton = styled(NavigationArrow)`
  right: -4px;
  transform: rotate(90deg);
`;

const NextArrowButton = styled(NavigationArrow)`
  left: -4px;
  transform: rotate(270deg);
`;

const FormStageButtonContainer = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  padding: 16px 0;

  @media screen and (max-width: 832px) {
    padding: 8px 0;
    font-size: 0.75rem;
  }
`;

const FormStage = ({
  formStage,
  setFormStage,
  setPreviousFormStage,
  setNextFormStage,
  backgroundColor,
  attendeeForm,
  preview,
  includeTicketing,
  largestFormStage,
  setLargestFormStage,
  switchFormStage,
  updateCheckListWidgetShow,
  locale,
}) => {
  const builtInPaymentEnabled = useBuiltInPaymentEnabled();
  const thankYouPageIndex = useThankYouPageIndex();
  const isThankYouStage = useMemo(
    () => (thankYouPageIndex === formStage),
    [thankYouPageIndex, formStage],
  );
  const formStageArray = useMemo(() => {
    const stageList = ['landing', 'ticketing', 'form', 'review_order', 'thank_you'];
    if (!builtInPaymentEnabled || !attendeeForm) {
      stageList.splice(3, 1);
    }
    if (!includeTicketing && (attendeeForm || preview)) {
      stageList.splice(1, 1);
    }
    return stageList;
  }, [includeTicketing, attendeeForm, preview]);
  useEffect(() => {
    if (formStage > largestFormStage) {
      setLargestFormStage(formStage);
    }
  }, [formStage]);
  const hasAttendeeForm = Boolean(attendeeForm);
  const updateFormStage = useCallback((index) => {
    if (hasAttendeeForm) {
      if (index <= largestFormStage) {
        setFormStage(index);
      }
    } else if (index !== formStage) {
      if (index === 3) {
        updateCheckListWidgetShow(true);
        setTimeout(() => {
          updateCheckListWidgetShow(false);
        }, 1000);
      }
      switchFormStage(index);
    }
  }, [hasAttendeeForm, formStage, largestFormStage, switchFormStage, updateCheckListWidgetShow]);
  const selectable = (index) => {
    if (attendeeForm) {
      return !isThankYouStage && index <= largestFormStage;
    }
    return true;
  };
  return (
    <FormStageSelectorWrapper id="FormStageSelector">
      <FormStageSelectorContainer
        justifyContent="space-between"
        alignContent="center"
        backgroundColor={backgroundColor}
      >
        <ArrowButtonContainer>
          {formStage !== 0 && !isThankYouStage && (
            <BackArrowButton
              src={Arrow}
              onClick={setPreviousFormStage}
            />
          )}
        </ArrowButtonContainer>
        <FormStageButtonContainer>
          {formStageArray.map((eachFormStage, index) => (
            <FormStageButton
              eachFormStage={eachFormStage}
              index={index}
              key={eachFormStage}
              selected={formStage === index}
              isSelectable={selectable(index)}
              updateFormStage={updateFormStage}
              locale={locale.emsMapping}
              numberSteps={formStageArray?.length}
            />
          ))}
        </FormStageButtonContainer>
        <ArrowButtonContainer>
          {largestFormStage > formStage && (
            <NextArrowButton
              src={Arrow}
              onClick={setNextFormStage}
            />
          )}
        </ArrowButtonContainer>
      </FormStageSelectorContainer>
    </FormStageSelectorWrapper>
  );
};

FormStage.propTypes = {
  formStage: PropTypes.number.isRequired,
  setFormStage: PropTypes.func.isRequired,
  setPreviousFormStage: PropTypes.func.isRequired,
  setNextFormStage: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  includeTicketing: PropTypes.bool.isRequired,
  attendeeForm: PropTypes.bool,
  largestFormStage: PropTypes.number,
  setLargestFormStage: PropTypes.func,
  preview: PropTypes.bool,
  switchFormStage: PropTypes.func,
  updateCheckListWidgetShow: PropTypes.func,
};

FormStage.defaultProps = {
  largestFormStage: 0,
  setLargestFormStage: () => {},
  preview: false,
  attendeeForm: false,
  switchFormStage: () => {},
  updateCheckListWidgetShow: () => {},
};

const mapState = (state) => ({
  formStage: state.formStage,
  backgroundColor: state.backgroundColor,
  locale: state.locale,
  includeTicketing: state.includeTicketing,
  preview: state.preview,
  largestFormStage: state.largestFormStage,
});

const mapDispatch = ({
  formStage: { setFormStage, setPreviousFormStage, setNextFormStage },
}) => ({
  setFormStage,
  setPreviousFormStage,
  setNextFormStage,
});

export default connect(mapState, mapDispatch)(FormStage);
